import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NewFunctionalitiesSchema } from '../types/NewFunctionalitiesSchema'
import { v4 as uuidv4 } from 'uuid'
import { FunctionalitiesTemplatesModified } from '../../../../entities/Functionality'
import {
	FunctionalityValueType
} from '../../../../entities/Functionality/model/types/FunctionalityTemplate'

const getEmptyFunctionality = (): FunctionalitiesTemplatesModified => ({
	functionalityTemplateId: uuidv4(),
	functionalityName: '',
	displayName: '',
	productId: '',
	isProductSetValue: false,
	values: [{ valueId: uuidv4(), value: '' }],
	type: FunctionalityValueType.ENUM_VALUE
})

const initialState: NewFunctionalitiesSchema = {
	functionalities: [getEmptyFunctionality()],
	isLoading: false,
	error: ''
}

const addFunctionalitiesSlice = createSlice(
	{
		name: 'addFunctionalitiesSlice',
		initialState: initialState,
		reducers: {
			resetState: (state) => {
				state.functionalities = [getEmptyFunctionality()]
			},
			setEmptyFunctionality: (state) => {
				state.functionalities.push(getEmptyFunctionality())
			},
			removeFunctionality: (state, action: PayloadAction<number>) => {
				state.functionalities.splice(action.payload, 1)
			},
			setEmptyFunctionalityValue: (
				state,
				action: PayloadAction<number>
			) => {
				state.functionalities[action.payload]?.values?.push({
					valueId: uuidv4(),
					value: ''
				})
			},
			removeFunctionalityValue: (
				state,
				action: PayloadAction<{ funcIndex: number, valueId: string }>
			) => {
				const { funcIndex, valueId } = action.payload
				state.functionalities[funcIndex].values = state.functionalities[funcIndex]?.values?.filter(
					(value) => value.valueId !== valueId
				) ?? state.functionalities[funcIndex]?.values
			},
			setFunctionalityName: (
				state,
				action: PayloadAction<{ funcName: string, funcIndex: number }>
			) => {
				const { funcIndex, funcName } = action.payload
				state.functionalities[funcIndex].functionalityName = funcName
			},
			setFunctionalityDisplayName: (
				state,
				action: PayloadAction<{ displayName: string, funcIndex: number }>
			) => {
				const { funcIndex, displayName } = action.payload
				state.functionalities[funcIndex].displayName = displayName
			},
			setFunctionalityValue: (
				state,
				action: PayloadAction<{
					value: string,
					funcIndex: number,
					valueId: string
				}>
			) => {
				const { value, funcIndex, valueId } = action.payload
				state.functionalities[funcIndex].values = state.functionalities[funcIndex].values?.map(
					(valueData) => {
						if (valueData.valueId === valueId) {
							return { ...valueData, value: value }
						}
						return valueData
					}) ?? state.functionalities[funcIndex].values
			},
			enableIsProductSetValue: (state, action: PayloadAction<{
				funcIndex: number,
			}>) => {
				const { funcIndex } = action.payload
				state.functionalities[funcIndex].isProductSetValue = true
				state.functionalities[funcIndex].type = FunctionalityValueType.INTEGER_VALUE
				state.functionalities[funcIndex].values = null
			},
			disableIsProductSetValue: (state, action: PayloadAction<{
				funcIndex: number,
			}>) => {
				const { funcIndex } = action.payload
				state.functionalities[funcIndex].isProductSetValue = false
				state.functionalities[funcIndex].type = FunctionalityValueType.ENUM_VALUE
				state.functionalities[funcIndex].values = [{
					valueId: uuidv4(),
					value: ''
				}]
			}
		}
	}
)

export const { reducer: addFunctionalitiesReducer } = addFunctionalitiesSlice
export const { actions: addFunctionalitiesActions } = addFunctionalitiesSlice
