import { memo } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

const style = { m: '0', p: '0' }

interface AddFunctionalityBtnProps {
	onAddPreset: () => void
	className?: string;
}

export const AddNewPresetBtn = memo((props: AddFunctionalityBtnProps) => {
	const {
		className,
		onAddPreset
	} = props

	return (
		<Box sx={{ display: 'flex' }}>
			<Typography sx={style}>Add new preset</Typography>
			<Button
				sx={style}
				className={className}
				onClick={onAddPreset}
			>
				+
			</Button>
		</Box>
	)
})