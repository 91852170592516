import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IProduct, IProductCore, ProductSchema } from '../types/Product'
import { Component } from '../types/Component'
import { fetchProduct } from '../services/fetchProduct'
import { mapProductPermissions } from '../lib/mappers/mapProductPermissions'
import {
	FunctionalityValueType
} from '../../../Functionality/model/types/FunctionalityTemplate'

const productCore: IProductCore = {
	name: '',
	platform: '',
	description: '',
	tier: '',
	businessGoal: '',
	yearOfDevelopment: '',
	combinedWithProducts: [],
	components: [
		{
			key: '',
			jiraLink: '',
			repositoryLink: '',
			componentName: ''
		}
	],
	dependencies: [],
	isApiDataUsed: false,
	isToolboxCompatible: false,
	cloudLink: ''
}

const product: IProduct = {
	...productCore,
	productId: '',
	mt4Permissions: [],
	mt5Permissions: [],
	functionalitiesTemplates: [{
		functionalityTemplateId: '',
		functionalityName: '',
		displayName: '',
		productId: '',
		isProductSetValue: false,
		values: [],
		type: FunctionalityValueType.ENUM_VALUE
	}],
	functionalitiesPresets: []
}

const initialState: ProductSchema = {
	isLoading: false,
	error: '',
	productData: product,
	editableProduct: {
		...productCore,
		requiredPermissions: {
			mt4Permissions: [],
			mt5Permissions: []
		},
		functionalitiesPresets: []
	}
}

const productSlice = createSlice({
	name: 'product',
	initialState,
	reducers: {
		setName: (state, action) => {
			state.editableProduct.name = action.payload
		},
		setPlatform: (state, action) => {
			state.editableProduct.platform = action.payload
		},
		setDescription: (state, action) => {
			state.editableProduct.description = action.payload
		},
		setTier: (state, action) => {
			state.editableProduct.tier = action.payload
		},
		setBusinessGoal: (state, action) => {
			state.editableProduct.businessGoal = action.payload
		},
		setYearOfDevelopment: (state, action) => {
			state.editableProduct.yearOfDevelopment = action.payload
		},
		setWebConfiguratorCompatability: (state, action) => {
			state.editableProduct.isToolboxCompatible = action.payload
		},
		setCombineWithProducts: (state, action) => {
			state.editableProduct.combinedWithProducts = action.payload
		},
		setIsApiDataUsed: (state, action) => {
			state.editableProduct.isApiDataUsed = action.payload
		},
		setComponents: (
			state,
			action: PayloadAction<{
				name: keyof Component
				value: string
				index: number
			}>
		) => {
			const { name, value, index } = action.payload
			state.editableProduct.components[index][name] = value
		},
		setNewComponent: (state) => {
			state.editableProduct.components.push({
				key: '',
				jiraLink: '',
				repositoryLink: '',
				componentName: ''
			})
		},
		removeComponent: (state, action: PayloadAction<number>) => {
			state.editableProduct.components.splice(action.payload, 1)
		},
		setRequiredPermissions: (state, action) => {
			state.editableProduct.requiredPermissions = action.payload
		},
		setMT4Permissions: (state, action) => {
			state.editableProduct.requiredPermissions.mt4Permissions =
				action.payload
		},
		setMT5Permissions: (state, action) => {
			state.editableProduct.requiredPermissions.mt5Permissions =
				action.payload
		},
		setDependencies: (state, action) => {
			state.editableProduct.dependencies = action.payload
		},
		setCloudLink: (state, action) => {
			state.editableProduct.cloudLink = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchProduct.pending, (state) => {
				state.isLoading = true
				state.error = ''
			})
			.addCase(fetchProduct.fulfilled, (state, action) => {
				state.isLoading = false
				state.productData = action.payload

				state.editableProduct = mapProductPermissions(action.payload)
			})
			.addCase(fetchProduct.rejected, (state, action) => {
				state.isLoading = false
				state.error = action.payload
			})
	}
})

export const { actions: productActions } = productSlice
export const { reducer: productReducer } = productSlice
