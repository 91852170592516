import React, { memo } from 'react'
import { FunctionalityPick, FunctionalityTemplate } from '../../../entities/Functionality'
import { useAppSelector } from '../../../shared/lib/hooks/useAppSelector/useAppSelector'
import { selectNewPreset } from '../model/selectors/selectNewPreset'
import { OneFunctionalityInSet } from '../../../entities/License'
import { useAppDispatch } from '../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { addNewPresetActions } from '../model/slice/newPresetSlice'

interface NewPresetFunctionalityItemProps {
	functionalityTemplate: FunctionalityTemplate
}

export const NewPresetFunctionalityItem = memo((props: NewPresetFunctionalityItemProps) => {
	const dispatch = useAppDispatch()
	const { functionalityTemplate } = props

	const { items: newPresetItems } = useAppSelector(selectNewPreset) || {}
	const funcItemValue = newPresetItems[functionalityTemplate.functionalityTemplateId]!
	const isFunctionalityPicked = Object.hasOwn(newPresetItems, functionalityTemplate.functionalityTemplateId)

	const handleFunctionalityValueChange = (value: OneFunctionalityInSet) => {
		dispatch(addNewPresetActions.setPresetItemValue(value))
	}

	const handleDisableFunctionality = (funcId: string) => {
		dispatch(addNewPresetActions.disableFunctionality(funcId))
	}

	const handleEnableFunctionality = (funcId: string) => {
		dispatch(addNewPresetActions.enableFunctionality(funcId))
	}

	return (
		<FunctionalityPick
			key={functionalityTemplate.functionalityTemplateId}
			functionalityTemplate={functionalityTemplate}
			value={funcItemValue!}
			onFunctionalityValueChange={handleFunctionalityValueChange}
			enableFunctionalityPermission={handleEnableFunctionality}
			disableFunctionalityPermission={handleDisableFunctionality}
			isFunctionalityPicked={isFunctionalityPicked}
		/>
	)
})