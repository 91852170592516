import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
	FunctionalitiesPresetsSchema,
	FunctionalityPreset
} from '../types/FunctionalityPreset'
import { FunctionalityValidator } from '../../../FunctionalityValidator'

const initialState: FunctionalitiesPresetsSchema = {
	error: '',
	isLoading: false,
	cachedFunctionalitiesPresets: [],
	functionalitiesPresetsEditable: []
}

const functionalitiesPresetsSlice = createSlice({
	name: 'functionalitiesPresets',
	initialState,
	reducers: {
		setFunctionalitiesPresets: (
			state, action: PayloadAction<Array<FunctionalityPreset>>
		) => {
			state.cachedFunctionalitiesPresets = action.payload
			state.functionalitiesPresetsEditable = action.payload
		},
		resetFunctionalitiesPreset: (
			state,
			action: PayloadAction<number>
		) => {
			const presetIndex = action.payload
			state.functionalitiesPresetsEditable[presetIndex] = state.cachedFunctionalitiesPresets[presetIndex]
		},
		editFunctionalityPresetName: (
			state,
			action: PayloadAction<{ presetIndex: number, presetName: string }>
		) => {
			const { presetIndex, presetName } = action.payload
			state.functionalitiesPresetsEditable[presetIndex].presetName = presetName
		},
		editFunctionalityPresetDisplayName: (
			state,
			action: PayloadAction<{ presetIndex: number, displayName: string }>
		) => {
			const { presetIndex, displayName } = action.payload
			state.functionalitiesPresetsEditable[presetIndex].displayName = displayName
		},
		enableFunctionality: (state, action: PayloadAction<{
			presetIndex: number,
			functionalityTemplateId: string
		}>) => {
			const { presetIndex, functionalityTemplateId } = action.payload
			const preset = state.functionalitiesPresetsEditable[presetIndex]
			const func = preset.items[functionalityTemplateId]
			if (!func) {
				preset.items[functionalityTemplateId] = ''
			}
		},
		disableFunctionality: (state, action: PayloadAction<{
			presetIndex: number,
			functionalityTemplateId: string
		}>) => {
			const { presetIndex, functionalityTemplateId } = action.payload
			const preset = state.functionalitiesPresetsEditable[presetIndex]
			delete preset.items[functionalityTemplateId]
		},
		setPresetItemValue: (
			state,
			action: PayloadAction<{
				presetIndex: number,
				functionalityId: string,
				value: string | FunctionalityValidator[]
			}>) => {
			const { presetIndex, functionalityId, value } = action.payload
			const preset = state.functionalitiesPresetsEditable[presetIndex]
			preset.items[functionalityId] = value
		}
	}
})

export const { actions: presetsActions } = functionalitiesPresetsSlice
export const { reducer: presetsReducer } = functionalitiesPresetsSlice
