export enum FunctionalityValueType {
	ENUM_VALUE = 'enumValue',
	INTEGER_VALUE = 'integerValue'
}

export type FunctionalityTemplate = {
	productId: string
	functionalityTemplateId: string
	functionalityName: string
	displayName: string
} & ({
	isProductSetValue: false,
	type: FunctionalityValueType.ENUM_VALUE,
	values: Array<string> | null
} | {
	isProductSetValue: true,
	type: FunctionalityValueType.INTEGER_VALUE,
})

export interface FunctionalitiesTemplatesModified extends Omit<FunctionalityTemplate, 'values'> {
	values: Array<{
		valueId: string,
		value: string
	}> | null
}

export interface FunctionalitiesSchema {
	cachedFunctionalities: Array<FunctionalitiesTemplatesModified>
	functionalitiesEditable: Array<FunctionalitiesTemplatesModified>
	isLoading: boolean
	error?: string
}
