import React, { memo } from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { Column } from '../../types/Column'
import TableHead from '@mui/material/TableHead'
import { styled } from '@mui/material/styles'
import TableSortLabel from '@mui/material/TableSortLabel/TableSortLabel'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#2c405a',
        color: theme.palette.common.white,
        fontWeight: 500,
        fontSize: 16
    }
}))

type Order = 'asc' | 'desc' | undefined

interface MuiTableHeadProps {
	flag: boolean
	columns: Array<Column>
	onRequestSort?: (event: React.MouseEvent<unknown>, property: any) => void
	orderBy?: string
	order?: Order
}

export const MuiTableHead = memo((props: MuiTableHeadProps) => {
    const { columns, flag, onRequestSort, order, orderBy } = props

    const createSortHandler =
		(property: any) => (event: React.MouseEvent<unknown>) => {
		    onRequestSort?.(event, property)
		}

    return (
        <TableHead
            sx={{
                position: 'sticky',
                zIndex: 10,
                top: '0px',
                backgroundColor: 'white'
            }}
        >
            <TableRow sx={{ backgroundColor: '#f7f7f7' }}>
                {columns.map((column) => (
                    <StyledTableCell
                        key={column.id}
                        align={column.align}
                        sortDirection={orderBy === column.id ? order : false}
                        style={{
                            top: 57,
                            minWidth: column.minWidth
                        }}
                    >
                        {column.sortable ? (
                            <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : 'asc'}
                                onClick={createSortHandler(column.id)}
                            >
                                {column.label}
                            </TableSortLabel>
                        ) : column.label}
                    </StyledTableCell>
                ))}
                {flag ? (
                    <StyledTableCell />
                ) : null}
            </TableRow>
        </TableHead>
    )
})
