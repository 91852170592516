import React, { forwardRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faExclamationCircle
} from '@fortawesome/free-solid-svg-icons/faExclamationCircle'

export const FunctionalityViolationMark = forwardRef<any>(function FunctionalityViolationMark(props, ref) {
	return (
		<span {...props} ref={ref}>
            <FontAwesomeIcon
	            icon={faExclamationCircle}
	            style={{ margin: '0 4px' }}
	            color="#DBB47A"
            />
        </span>
	)
})