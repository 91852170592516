import { IInstance } from '../types/Instance'

export const getFunctionalityCurrentValueMinMax = (instances: IInstance[]) =>
	instances.reduce((minMaxAcc: Record<string, {
		least: number,
		greatest: number
	}>, instance) => {
		const funcValues = instance.functionalityValues
		for (const key in funcValues) {
			if (typeof funcValues[key] !== 'number') continue

			if (!(key in minMaxAcc)) {
				minMaxAcc[key] = {
					least: funcValues[key],
					greatest: funcValues[key]
				}
			} else {
				if (funcValues[key] < minMaxAcc[key].least) minMaxAcc[key].least = funcValues[key]
				if (funcValues[key] > minMaxAcc[key].greatest) minMaxAcc[key].greatest = funcValues[key]
			}
		}
		return minMaxAcc
	}, {})