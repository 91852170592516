import { ChangeEvent, memo } from 'react'
import { MuiInput } from '../../../shared/ui/Inputs/MuiInput/MuiInput'

interface PresetNameProps {
	value: string
	onChange: (value: string) => void
	className?: string
}

export const PresetName = memo((props: PresetNameProps) => {
	const { value, onChange } = props

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.value)
	}
	return (
		<MuiInput
			inputType="text"
			label="Preset name"
			required
			value={value}
			handleChange={handleChange}
			size="medium"
		/>
	)

})