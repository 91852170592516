import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { showToast } from '../../../../shared/lib/showToast/showToast'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
import { FunctionalityPreset } from '../../../../entities/FunctionalityPreset'
import { fetchProduct } from '../../../../entities/Product/model/services/fetchProduct'
import { fetchCommonProducts } from '../../../../entities/Common'

export const addNewPreset = createAsyncThunk<void, {
	productId: string,
	newPreset: FunctionalityPreset
}, ThunkConfig<string>>(
	'newPreset/addNewPreset',
	async (arg, thunkAPI) => {
		const { productId, newPreset } = arg
		const {
			dispatch,
			extra: { api },
			rejectWithValue
		} = thunkAPI

		try {
			await api.post(`/products/${productId}/functionality-presets`, newPreset)

			showToast({
				type: 'success',
				toastId: 'newPresetCreated',
				message: 'New preset created'
			})

			dispatch(fetchCommonProducts())
			dispatch(fetchProduct(productId))
		} catch (e: any) {
			console.log(e)
			appHandleError(e, 'newPresetCreated', 'Failed to add a new preset')
			return rejectWithValue('Error occurred while creating a new preset')
		}
	}
)