import { useAppDispatch } from '../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { useAppSelector } from '../../../shared/lib/hooks/useAppSelector/useAppSelector'
import { modalActions } from '../../../entities/Modal'
import { ChangeStateModal } from '../../../shared/ui/ChangeStateModal/ChangeStateModal'
import { FunctionalityPreset } from '../../../entities/FunctionalityPreset'
import { deletePreset } from '../model/services/deletePreset'

export const DeletePresetModal = () => {

	const dispatch = useAppDispatch()
	const preset = useAppSelector<FunctionalityPreset>((state) => state.modal.data)

	const header = (
		<>
			Please confirm that you want to delete the preset &nbsp;
			<i>{preset.displayName}</i>
		</>
	)

	const handleConfirm = () => {
		dispatch(deletePreset(preset.functionalitiesPresetId))
		dispatch(modalActions.hideModal())
	}

	return (
		<ChangeStateModal
			handleConfirm={handleConfirm}
			headerMsg={header}
		/>
	)
}