import { IInstance } from '../../../entities/Instances'
import {
	FunctionalityValidator,
	LimitTypes
} from '../../../entities/FunctionalityValidator'
import Tooltip from '@mui/material/Tooltip'
import { FunctionalityViolationMark } from '../../../entities/Functionality'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

interface InstanceNameAndIndicatorProps {
	instance: IInstance
	validators: Array<FunctionalityValidator>
	funcTemplateId: string
}

export const InstanceCurrentValue = (props: InstanceNameAndIndicatorProps) => {
	const { instance, validators, funcTemplateId } = props

	const currentValue = instance.functionalityValues[funcTemplateId]

	let isInvalid = false
	for (let validator of validators) {
		if (validator.limitType === LimitTypes.MAX && currentValue > validator.limitValue) {
			isInvalid = true
			break
		}
		if (validator.limitType === LimitTypes.MIN && currentValue < validator.limitValue) {
			isInvalid = true
			break
		}
	}

	const violationWarning = 'Value violates functionality validator'

	return (
		<Stack direction="row" gap={2}>
			<Box component="span">{currentValue}</Box>
			{isInvalid && (
				<Tooltip
					title={violationWarning}
					placement="top"
				>
					<FunctionalityViolationMark />
				</Tooltip>
			)}
		</Stack>)
}