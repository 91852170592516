import React, { BaseSyntheticEvent } from 'react'
import { ModalForm } from '../../../shared/ui/ModalForm/ModalForm'
import { SubmitHandler, useForm } from 'react-hook-form'
import { modalActions } from '../../../entities/Modal'
import { useAppDispatch } from '../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import {
	PresetDisplayName,
	PresetName,
	presetsActions,
	selectPresetsEditable
} from '../../../entities/FunctionalityPreset'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { useAppSelector } from '../../../shared/lib/hooks/useAppSelector/useAppSelector'
import { selectProductFunctionalities, selectProductId } from '../../../entities/Product'
import { PresetFunctionalityItem } from './PresetFunctionalityItem'
import { editPresetService } from '../model/services/editPresetService'
import { isFunctionalitiesSetValid } from '../../../entities/License'

const modalStyles = {
	width: 865
}

export const EditPresetModal = () => {

	const dispatch = useAppDispatch()
	const {
		formState: { isValid },
		handleSubmit,
		reset
	} = useForm({ mode: 'onBlur' })

	const productId = useAppSelector(selectProductId)
	const functionalities = useAppSelector(selectProductFunctionalities)
	const presets = useAppSelector(selectPresetsEditable)
	const presetIndex = useAppSelector(state => state.modal.data) as number
	const preset = presets?.[presetIndex]
	const presetItems = preset?.items || {}

	const presetItemsEntries = Object.entries(presetItems)
	const isPresetItemsValid = presetItemsEntries.length
		? isFunctionalitiesSetValid(presetItemsEntries)
		: true

	const isFormValid = isValid && isPresetItemsValid

	const handleCancel = () => {
		dispatch(modalActions.hideModal())
		dispatch(presetsActions.resetFunctionalitiesPreset(presetIndex))
		reset()
	}

	const onSubmit: SubmitHandler<{}> = async (_, event) => {
		event?.preventDefault()
		await dispatch(editPresetService({ productId, preset }))
		dispatch(modalActions.hideModal())
		reset()
	}

	const handlePresetNameChange = (presetName: string) => {
		dispatch(presetsActions.editFunctionalityPresetName({ presetIndex, presetName }))
	}
	const handleDisplayNameChange = (displayName: string) => {
		dispatch(presetsActions.editFunctionalityPresetDisplayName({
			presetIndex,
			displayName
		}))
	}

	return (
		<ModalForm
			disabled={!isFormValid}
			styles={modalStyles}
			handleCancel={handleCancel}
			modalTitle={'Edit functionalities preset'}
			handleOnSubmit={(e: BaseSyntheticEvent) =>
				handleSubmit(onSubmit({}, e))
			}
		>
			<Stack direction="column" gap={2}>
				<PresetName
					value={preset?.presetName ?? ''}
					onChange={handlePresetNameChange}
				/>
				<PresetDisplayName
					value={preset?.displayName ?? ''}
					onChange={handleDisplayNameChange}
				/>
				<Typography
					sx={{ mb: '0.5rem' }}
					variant="h6"
				>
					Functionalities set *
				</Typography>
			</Stack>
			{functionalities.map((functionalityTemplate) => (
				<PresetFunctionalityItem
					key={functionalityTemplate.functionalityTemplateId}
					functionalityTemplate={functionalityTemplate}
					presetIndex={presetIndex}
					preset={preset}
				/>
			))}
		</ModalForm>
	)
}