import { FunctionalityValidator } from '../../../../FunctionalityValidator'

export const isFunctionalitiesSetValid = (funcEntries: Array<[string, string | FunctionalityValidator[]]>) => {
	return funcEntries.every(([_, value]) => {
		if (typeof value === 'string' || value === null || value === undefined) {
			return Boolean(value)
		}
		if (typeof value === 'object') {
			if (Array.isArray(value)) {
				return value.every(validator => {
					return validator.limitType && (validator.limitValue || validator.limitValue === 0)
				})
			} else {
				return false
			}
		}
		return Boolean(value)
	})
}