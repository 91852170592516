import { ChangeEvent, memo } from 'react'
import { MuiInput } from '../../../shared/ui/Inputs/MuiInput/MuiInput'

interface PresetDisplayNameProps {
	value: string
	onChange: (value: string) => void
	className?: string
}

export const PresetDisplayName = memo((props: PresetDisplayNameProps) => {
	const { value, onChange, className } = props

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.value)
	}

	return (
		<MuiInput
			inputType="text"
			label="Preset display name"
			required
			value={value}
			handleChange={handleChange}
			size="medium"
		/>
	)

})