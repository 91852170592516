import { modalActions } from '../../../entities/Modal'
import { PropsWithChildren, ReactNode, useMemo } from 'react'
import { useAppDispatch } from '../../lib/hooks/useAppDispatch/useAppDispatch'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/material'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: 2,
    p: 4
}

interface ChangeStateModalProps extends PropsWithChildren {
	handleConfirm: () => void
	headerMsg?: ReactNode
	onCancel?: () => void
	disabled?: boolean
	styles?: SxProps
}

export const ChangeStateModal = (props: ChangeStateModalProps) => {
    const {
        handleConfirm,
        headerMsg,
        onCancel,
        children,
        disabled = false,
        styles
    } = props
    const dispatch = useAppDispatch()

    const sxStyle = useMemo(() => ({ ...style, ...styles }), [styles])

    const handleClose = () => {
        if (onCancel) {
            onCancel()
            return
        }
        dispatch(modalActions.hideModal())
    }

    return (
        <Modal
            open={true}
            onClose={handleClose}
        >
            <Box sx={sxStyle}>
                {headerMsg && (
                    <Typography
                        sx={{ mb: '1rem', fontWeight: 600 }}
                        component="h6"
                        variant="h6"
                    >
                        {headerMsg}
                    </Typography>
                )}
                {children}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={handleClose}
                    >
						Cancel
                    </Button>
                    <Button
                        disabled={disabled}
                        sx={{ ml: '0.5rem' }}
                        variant="contained"
                        onClick={handleConfirm}
                    >
						Confirm
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}
