import { ThunkExtraArgument } from '../../../../shared/config/storeConfig/StoreSchema'
import { configureStore } from '@reduxjs/toolkit'
import { $API } from '../../../../shared/api/api'
import { roleReducer } from '../../../../entities/Role'
import { userReducer } from '../../../../entities/User'
import { modalReducer } from '../../../../entities/Modal'
import { loginReducer } from '../../../../pages/LoginPage'
import { commonReducer } from '../../../../entities/Common'
import { clientReducer } from '../../../../entities/Client'
import { productReducer } from '../../../../entities/Product'
import { licenseReducer } from '../../../../entities/License'
import { commentsReducer } from '../../../../entities/Comment'
import { authUserReducer } from '../../../../entities/AuthUser'
import { instancesReducer } from '../../../../entities/Instances'
import { presetsReducer } from '../../../../entities/FunctionalityPreset'
import { functionalitiesReducer } from '../../../../entities/Functionality'
import { productPublicKeyReducer } from '../../../../features/GetProductPublicKey'
import { similarNamesReducer } from '../../../../features/DisplaySimilarNames'
import { addNewPresetReducer } from '../../../../features/AddNewPreset'
import { newProductReducer } from '../../../../features/AddNewProduct'
import { newLicenseReducer } from '../../../../features/AddNewLicense'
import { newClientReducer } from '../../../../features/AddNewClient'
import { newUserReducer } from '../../../../features/AddNewUser'
import {
	addFunctionalitiesReducer
} from '../../../../features/AddFunctionalitiesToProduct'
import { licensesReducer } from '../../../../pages/LicensesPage'
import { productsReducer } from '../../../../pages/ProductsPage'
import { clientsReducer } from '../../../../pages/ClientsPage'
import { usersReducer } from '../../../../pages/UsersPage'

const ThunkExtraArg: ThunkExtraArgument = {
	api: $API
}

export const store = configureStore({
	reducer: {
		authUser: authUserReducer,
		user: userReducer,
		client: clientReducer,
		license: licenseReducer,
		instances: instancesReducer,
		product: productReducer,
		comment: commentsReducer,
		modal: modalReducer,
		newUserState: newUserReducer,
		newClientState: newClientReducer,
		newProductState: newProductReducer,
		newLicenseState: newLicenseReducer,
		users: usersReducer,
		clients: clientsReducer,
		loginState: loginReducer,
		licenses: licensesReducer,
		products: productsReducer,
		commonData: commonReducer,
		productKey: productPublicKeyReducer,
		newFunctionalities: addFunctionalitiesReducer,
		functionalities: functionalitiesReducer,
		functionalitiesPresets: presetsReducer,
		newPreset: addNewPresetReducer,
		similarCompanyNames: similarNamesReducer,
		newUserRole: roleReducer
	},
	devTools: process.env.NODE_ENV === 'development',
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			thunk: {
				extraArgument: ThunkExtraArg
			}
		})
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
