import { FunctionalityWithDisplayName, ILicenseWithUpdates } from '../../types/License'

export const formFunctionalitiesWithDisplayName =
	(license: ILicenseWithUpdates): FunctionalityWithDisplayName[] => {
		const functionalitiesTemplates = license.productBrief.functionalitiesTemplates
		return Object.entries(license.functionalitiesSet ?? {})
			.map(([k, v]) => {
				let displayName = ''
				for (let funcTemplate of functionalitiesTemplates) {
					if (funcTemplate.functionalityTemplateId === k) {
						displayName = funcTemplate.displayName
						break
					}
				}
				return {
					functionalityTemplateId: String(k),
					value: v === null || typeof v === 'string' ? String(v) : v,
					displayName
				}
			})
	}
