import { useAppDispatch } from '../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { SubmitHandler, useForm } from 'react-hook-form'
import { modalActions } from '../../../entities/Modal'
import { ModalForm } from '../../../shared/ui/ModalForm/ModalForm'
import React, { BaseSyntheticEvent } from 'react'
import { PresetDisplayName, PresetName } from '../../../entities/FunctionalityPreset'
import Typography from '@mui/material/Typography'
import { useAppSelector } from '../../../shared/lib/hooks/useAppSelector/useAppSelector'
import { selectProductFunctionalities, selectProductId } from '../../../entities/Product'
import { NewPresetFunctionalityItem } from './NewPresetFunctionalityItem'
import { addNewPresetActions } from '../model/slice/newPresetSlice'
import { selectNewPreset } from '../model/selectors/selectNewPreset'
import Stack from '@mui/material/Stack'
import { addNewPreset } from '../model/services/addNewPresetService'
import { isFunctionalitiesSetValid } from '../../../entities/License'

const modalStyles = { width: 865 }

export const AddNewPresetModal = () => {
	const dispatch = useAppDispatch()

	const {
		formState: { isValid },
		handleSubmit,
		reset
	} = useForm({ mode: 'onBlur' })

	const productId = useAppSelector(selectProductId)
	const functionalities = useAppSelector(selectProductFunctionalities)
	const newPreset = useAppSelector(selectNewPreset)
	const presetItems = newPreset.items

	const presetItemsEntries = Object.entries(presetItems)
	const isPresetItemsValid = presetItemsEntries.length
		? isFunctionalitiesSetValid(presetItemsEntries)
		: true

	const isFormValid = isValid && isPresetItemsValid

	const handleCancel = () => {
		dispatch(addNewPresetActions.resetState())
		dispatch(modalActions.hideModal())
		reset()
	}

	const onSubmit: SubmitHandler<{}> = async (_, event) => {
		event?.preventDefault()
		const result = await dispatch(addNewPreset({
			productId,
			newPreset
		}))
		if (result.meta.requestStatus === 'fulfilled') {
			dispatch(addNewPresetActions.resetState())
			dispatch(modalActions.hideModal())
			reset()
		}
	}

	const handlePresetNameChange = (value: string) => {
		dispatch(addNewPresetActions.setPresetName(value))
	}

	const handleDisplayNameChange = (value: string) => {
		dispatch(addNewPresetActions.setPresetDisplayName(value))
	}

	return (
		<ModalForm
			disabled={!isFormValid}
			handleCancel={handleCancel}
			styles={modalStyles}
			handleOnSubmit={(e: BaseSyntheticEvent) =>
				handleSubmit(onSubmit({}, e))
			}
			modalTitle={'Add new preset'}
		>
			<Stack direction="column" gap={2}>
				<PresetName
					value={newPreset.presetName}
					onChange={handlePresetNameChange}
				/>
				<PresetDisplayName
					value={newPreset.displayName}
					onChange={handleDisplayNameChange}
				/>
				<Typography
					sx={{ mb: '0.5rem' }}
					variant="h6"
				>
					Functionalities *
				</Typography>
			</Stack>
			{functionalities.map((functionalityTemplate) => (
				<NewPresetFunctionalityItem
					key={functionalityTemplate.functionalityTemplateId}
					functionalityTemplate={functionalityTemplate}
				/>
			))}
		</ModalForm>
	)
}