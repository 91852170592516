import { createAsyncThunk } from '@reduxjs/toolkit'
import { IProduct } from '../types/Product'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
import { functionalitiesAction } from '../../../Functionality'
import { mapProductFunctionalities } from '../lib/mappers/mapProductFunctionalities'
import { presetsActions } from '../../../FunctionalityPreset'

export const fetchProduct = createAsyncThunk<
	IProduct,
	string,
	ThunkConfig<string>
>('product/fetchProduct', async (productId, thunkAPI) => {
	const {
		extra: { api },
		rejectWithValue,
		dispatch
	} = thunkAPI

	try {
		const res = await api.get(`/products/${productId}`)

		const product: IProduct = res.data
		dispatch(functionalitiesAction.setFunctionalities(
			mapProductFunctionalities(product.functionalitiesTemplates)
		))
		dispatch(presetsActions.setFunctionalitiesPresets(product.functionalitiesPresets))

		return product
	} catch (e) {
		console.log(e)
		appHandleError(e, 'fetchProduct', 'Failed to fetch product data')
		return rejectWithValue('Error occurred on fetching product data')
	}
})
