export const LimitTypes = {
	MIN: 'min',
	MAX: 'max'
} as const

export type FunctionalityLimitType = typeof LimitTypes[keyof typeof LimitTypes]

export type FunctionalityValidator = {
	limitType: FunctionalityLimitType
	limitValue: number
}