import { memo, useCallback } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

const style = { m: '0', p: '0' }

interface AddFunctionalityValueBtnProps {
	index: number
	onAddFunctionalityValue: (index: number) => void
	isDisabled: boolean
	className?: string;
}

export const AddFunctionalityValueBtn = memo(
	(props: AddFunctionalityValueBtnProps) => {
		const {
			className,
			index,
			onAddFunctionalityValue,
			isDisabled = false
		} = props

		const handleAdd = useCallback(() => {
			onAddFunctionalityValue(index)
		}, [index, onAddFunctionalityValue])

		return (
			<Box sx={{ display: 'flex', marginLeft: 'auto' }}>
				<Typography sx={style}>Add new value</Typography>
				<Button
					sx={style}
					onClick={handleAdd}
					className={className}
					disabled={isDisabled}
				>
					+
				</Button>
			</Box>
		)
	})
