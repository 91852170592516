export interface Manager {
	userId: string
	fullName: string
	email: string
	position: string
	departmentName: string
}

export enum TfbOfficeClients {
	CYPRUS = 'cyprus',
	SINGAPORE = 'singapore',
	UAE = 'uae',
	UK = 'uk'
}

export enum ClientStatus {
	ACTIVE = 'active',
	PROSPECT = 'prospect',
	DEBTOR = 'debtor',
	CONTRACT_TERMINATION = 'contractTermination',
	MQ_REGULATION_ISSUES = 'mqRegulationIssues',
	BUSINESS_FINANCIAL_ISSUES = 'businessFinancialIssues',
	BUGS_AND_INCIDENTS = 'bugsAndIncidents',
	MISSED_FEATURE = 'missedFeature',
	OTHERS = 'others',
}

export interface IClient {
	clientId: string
	status: ClientStatus
	numberOfProducts: 0
	creationDate: string
	companyNames: string[]
	managersBrief: Array<Manager>
	tfbOffice?: TfbOfficeClients
}

export interface ClientSchema {
	clientData: IClient
	cachedClient: IClient
	managerNames: string[]
	isLoading: boolean
	error?: string
}
