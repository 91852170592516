import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import { FunctionalityTemplate } from '../index'
import { useState } from 'react'
import { FunctionalityValueType } from '../model/types/FunctionalityTemplate'
import { SelectChangeEvent } from '@mui/material/Select'
import {
	FunctionalityValidator,
	FunctionalityValidatorUi
} from '../../FunctionalityValidator'
import { OneFunctionalityInSet } from '../../License'
import { MuiSelect } from '../../../shared/ui/MuiSelect/MuiSelect'

const style = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	mb: '1rem'
}

const inputStyle = { width: '18.75rem', minWidth: '18.75rem' }

interface FunctionalityPickProps {
	functionalityTemplate: FunctionalityTemplate,
	value: string | Array<FunctionalityValidator>
	onFunctionalityValueChange: (value: OneFunctionalityInSet) => void
	disableFunctionalityPermission: (funcId: string) => void
	enableFunctionalityPermission: (funcId: string) => void
	isFunctionalityPicked?: boolean
	className?: string
}

export const FunctionalityPick = (props: FunctionalityPickProps) => {
	const {
		functionalityTemplate,
		value,
		onFunctionalityValueChange,
		disableFunctionalityPermission,
		enableFunctionalityPermission,
		isFunctionalityPicked,
		className
	} = props

	const [checked, setChecked] = useState(isFunctionalityPicked)

	const functionalityId = functionalityTemplate.functionalityTemplateId
	const label = functionalityTemplate.displayName
	const selectOptions =
		functionalityTemplate.type === FunctionalityValueType.ENUM_VALUE && functionalityTemplate.values
			? functionalityTemplate.values
			: []

	const handleFunctionalityPick = () => {
		setChecked(prev => {
			if (prev) {
				disableFunctionalityPermission(functionalityTemplate.functionalityTemplateId)
			}
			if (!prev) {
				enableFunctionalityPermission(functionalityTemplate.functionalityTemplateId)
			}
			return !prev
		})
	}

	const handleFunctionalityEnumSelect = (e: SelectChangeEvent) => {
		onFunctionalityValueChange({
			functionalityId: e.target.name,
			value: e.target.value
		})
	}

	const handleFunctionalityValidatorChange = (value: FunctionalityValidator[]) => {
		onFunctionalityValueChange({ functionalityId, value })
	}

	const input = functionalityTemplate.type === FunctionalityValueType.ENUM_VALUE ? (
		<MuiSelect
			styles={inputStyle}
			handleSelectChange={handleFunctionalityEnumSelect}
			selectOptions={selectOptions}
			disabled={!checked}
			labelId={functionalityId}
			name={functionalityId}
			value={value as string}
			label={label}
			size="medium"
		/>
	) : functionalityTemplate.type === FunctionalityValueType.INTEGER_VALUE ? (
		<FunctionalityValidatorUi
			functionalityId={functionalityId}
			value={value as FunctionalityValidator[] || []}
			isDisabled={!checked}
			onValidatorChange={handleFunctionalityValidatorChange}
		/>
	) : (<></>)

	return (
		<Box
			className={className}
			sx={style}
		>
			<FormControlLabel
				label={functionalityTemplate.displayName}
				control={
					<Checkbox
						onClick={handleFunctionalityPick}
						checked={checked}
					/>
				}
			/>
			{input}
		</Box>
	)
}