import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import { FunctionalityViolationMark } from '../../../Functionality'

interface LicenseInstanceNameAndViolationMarkProps {
	instanceName: string
	functionalityTemplateId: string | undefined
}

export const LicenseInstanceNameAndViolationMark = (props: LicenseInstanceNameAndViolationMarkProps) => {
	const { instanceName, functionalityTemplateId } = props

	const functionalityViolationWarning = 'Instance has value that violates functionality'

	return (
		<Stack direction="row" gap={2}>
			{!!functionalityTemplateId && (
				<Tooltip
					title={functionalityViolationWarning}
					placement="top"
				>
					<FunctionalityViolationMark />
				</Tooltip>
			)}
			<span>{instanceName}</span>
		</Stack>
	)
}