import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import { MuiTableHead } from '../../../shared/ui/MuiTableHead/MuiTableHead'
import TableBody from '@mui/material/TableBody'
import { StyledTableRow } from '../../../shared/ui/StyledTableRow/StyledTableRow'
import TableCell from '@mui/material/TableCell'
import { IInstance, selectInstances } from '../../../entities/Instances'
import CustomDialog from '../../../shared/ui/CustomDialog/CustomDialog'
import { modalActions, selectModalData } from '../../../entities/Modal'
import { Column } from '../../../shared/types/Column'
import { useAppDispatch } from '../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { useAppSelector } from '../../../shared/lib/hooks/useAppSelector/useAppSelector'
import React, { ReactNode } from 'react'
import { FunctionalityValidator } from '../../../entities/FunctionalityValidator'
import { InstanceCurrentValue } from './InstanceCurrentValue'

const columns: Array<Column> = [
	{ id: 'instanceName', label: 'Instance name', align: 'left' },
	{ id: 'currentValue', label: 'Current value', align: 'left' }
]

export const FunctionalityValuesModal = () => {
	const dispatch = useAppDispatch()
	const modalData = useAppSelector(selectModalData) ?? {}
	const instances = useAppSelector(selectInstances)

	const funcTemplateId = modalData.funcTemplateId
	const validators = modalData.validators ?? []
	const validatorsSummary = validators.map((validator: FunctionalityValidator) => {
		return `  ${validator.limitType ?? ''} ${validator.limitValue ?? ''}`
	}).join(',')

	const handleClose = () => {
		dispatch(modalActions.hideModal())
	}

	return (
		<CustomDialog
			dialogTitle={`${modalData.functionalityName}${validatorsSummary ? ': ' + validatorsSummary : ''}`}
			handleClose={handleClose}
		>
			<TableContainer sx={{ maxHeight: 500 }}>
				<Table aria-label="sticky table">
					<MuiTableHead
						flag={false}
						columns={columns}
					/>
					<TableBody>
						{instances.map((instance) => (
							<StyledTableRow
								hover
								role="checkbox"
								tabIndex={-1}
								key={instance.instanceId}
							>
								{
									columns.map((column) => {
										const value = instance?.[column.id as keyof IInstance]
										return (
											<TableCell
												key={column.id}
												align={column.align}
											>
												{column.id === 'currentValue' ? (
													<InstanceCurrentValue
														instance={instance}
														validators={validators}
														funcTemplateId={funcTemplateId}
													/>
												) : value as ReactNode}
											</TableCell>
										)
									})
								}
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</CustomDialog>
	)
}