import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { showToast } from '../../../../shared/lib/showToast/showToast'
import { fetchProduct } from '../../../../entities/Product/model/services/fetchProduct'
import { fetchCommonProducts } from '../../../../entities/Common'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'
import { FunctionalityPreset } from '../../../../entities/FunctionalityPreset'

export const editPresetService = createAsyncThunk<
	void,
	{ productId: string, preset: FunctionalityPreset },
	ThunkConfig<string>
>('editPresetService', async (data, thunkAPI) => {
	const { dispatch, extra: { api }, rejectWithValue } = thunkAPI

	const { productId, preset } = data

	try {
		await api.put(
			`/products/${productId}/functionality-presets/${preset.functionalitiesPresetId}`,
			preset
		)

		showToast({
			type: 'success',
			toastId: 'editPresetService',
			message: 'Success'
		})

		dispatch(fetchProduct(productId))
		dispatch(fetchCommonProducts())
	} catch (e) {
		console.log(e)
		appHandleError(e, 'editPresetService', 'Edit failed')
		return rejectWithValue('Error occurred on editing functionalities preset')
	}
})