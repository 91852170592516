import {
	FunctionalitiesTemplatesModified,
	FunctionalityTemplate
} from '../../../../Functionality'
import { v4 as uuidv4 } from 'uuid'
import {
	FunctionalityValueType
} from '../../../../Functionality/model/types/FunctionalityTemplate'

export const mapProductFunctionalities =
	(functionalitiesTemplates: Array<FunctionalityTemplate>): Array<FunctionalitiesTemplatesModified> => {
		return functionalitiesTemplates.map(
			(functionality) => {
				const valuesMapped = (
					functionality.type === FunctionalityValueType.ENUM_VALUE && functionality.values?.length
				) ? functionality.values.map((val) => {
					return { valueId: uuidv4(), value: val }
				}) : []
				return { ...functionality, values: valuesMapped }
			}
		)
	}
