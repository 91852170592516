import { PropsWithChildren } from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { SvgIcon } from '../SvgIcon/SvgIcon'
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark'
import Box from '@mui/material/Box'

const BootstrapStyleDialog = styled(Dialog)(() => ({
	'& .MuiDialog-paper': {
		borderRadius: '8px'
	},
	'& .MuiDialogContent-root': {
		padding: '1.5rem'
	},
	'& .MuiDialogActions-root': {
		padding: '1.5rem'
	}
}))

export interface CustomDialogTitleProps {
	onClose: () => void;
	title: string
}

const dialogTitleStyle = {
	m: 0,
	p: '1rem 1.5rem 1rem',
	fontSize: '1.5rem',
	display: 'flex',
	justifyContent: 'space-between'
}

function CustomDialogTitle(props: CustomDialogTitleProps) {
	const { title, onClose, ...other } = props

	return (
		<DialogTitle sx={dialogTitleStyle} {...other}>
			{title}
			<Box
				component="span"
				sx={{ ml: 3, cursor: 'pointer' }}
				onClick={onClose}
			>
				<SvgIcon icon={faXmark} />
			</Box>
		</DialogTitle>
	)
}

interface CustomDialogProps extends PropsWithChildren {
	handleClose: () => void
	dialogTitle: string
}

export default function CustomDialog(props: CustomDialogProps) {
	const { dialogTitle, handleClose, children } = props
	return (
		<BootstrapStyleDialog
			onClose={handleClose}
			open={true}
		>
			<CustomDialogTitle onClose={handleClose} title={dialogTitle} />
			<DialogContent dividers>
				{children}
			</DialogContent>
		</BootstrapStyleDialog>
	)
}
