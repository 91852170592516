import { MuiSelect } from '../../../shared/ui/MuiSelect/MuiSelect'
import { MuiInput } from '../../../shared/ui/Inputs/MuiInput/MuiInput'
import { ChangeEvent, memo, useState } from 'react'
import { FunctionalityValidator, LimitTypes } from '../model/types/FunctionalityValidator'
import { SelectChangeEvent } from '@mui/material/Select'

const containerStyle = {
	display: 'flex',
	gap: '3rem',
	width: '18.75rem'
}
const inputStyle = { width: '8rem', minWidth: '8rem' }

interface FunctionalityValidatorUiProps {
	functionalityId: string
	value: FunctionalityValidator[]
	isDisabled: boolean
	onValidatorChange: (value: FunctionalityValidator[]) => void
}

export const FunctionalityValidatorUi = memo((props: FunctionalityValidatorUiProps) => {
	const {
		functionalityId,
		value,
		isDisabled,
		onValidatorChange
	} = props

	// To be changed when several validators are needed
	const firstValidator = Array.isArray(value) ? value[0] : null

	const [, setValidator] = useState({
		limitType: firstValidator?.limitType ?? '',
		limitValue: firstValidator?.limitValue ?? ''
	})

	const handleSelect = (e: SelectChangeEvent) => {
		setValidator(prev => {
			onValidatorChange([{
				...prev,
				limitType: e.target.value
			}] as unknown as FunctionalityValidator[])
			return { ...prev, limitType: e.target.value }
		})
	}

	const handleIntInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (/^\d*$/.test(e.target.value)) {
			setValidator(prev => {
				onValidatorChange([{
					...prev,
					limitValue: Number(e.target.value)
				}] as unknown as FunctionalityValidator[])
				return { ...prev, limitValue: e.target.value }
			})
		}
	}

	return (
		<div style={containerStyle}>
			<MuiSelect
				styles={inputStyle}
				disabled={isDisabled}
				handleSelectChange={handleSelect}
				selectOptions={LimitTypes}
				value={firstValidator?.limitType}
				label={'Limit type *'}
				labelId={`limitType${functionalityId}`}
				size="medium"
			/>
			<MuiInput
				inputType={'number'}
				styles={inputStyle}
				disabled={isDisabled}
				handleChange={handleIntInputChange}
				value={firstValidator?.limitValue === 0 ? '0' : firstValidator?.limitValue}
				label={'Limit value'}
				size="medium"
				required
			/>
		</div>
	)
})