import { StoreSchema } from '../../../../shared/config/storeConfig/StoreSchema'
import { createSelector } from '@reduxjs/toolkit'

export const selectProductFunctionalities = (store: StoreSchema) => store.product.productData.functionalitiesTemplates

export const selectProductFunctionalitiesMap = createSelector(
	[selectProductFunctionalities],
	(funcTemplates) => {
		return funcTemplates.reduce((acc: any, cur) => {
			acc[cur.functionalityTemplateId] = cur.displayName
			return acc
		}, {})
	}
)