import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NewPresetSchema } from '../types/NewPreset'
import { FunctionalityValidator } from '../../../../entities/FunctionalityValidator'
import { v4 as uuidv4 } from 'uuid'

const getEmptyPreset = () => ({
	functionalitiesPresetId: uuidv4(),
	presetName: '',
	displayName: '',
	items: {}
})

const initialState: NewPresetSchema = {
	preset: getEmptyPreset(),
	isLoading: false,
	error: ''
}

const newPresetSlice = createSlice(
	{
		name: 'newPresetSlice',
		initialState: initialState,
		reducers: {
			resetState: (state) => {
				state.preset = getEmptyPreset()
			},
			setPresetName: (state, action: PayloadAction<string>) => {
				state.preset.presetName = action.payload
			},
			setPresetDisplayName: (state, action: PayloadAction<string>) => {
				state.preset.displayName = action.payload
			},
			setPresetFunctionalities: (state, action: PayloadAction<{
				functionalityId: string,
				value: string | FunctionalityValidator[]
			}>) => {
				state.preset.items[action.payload.functionalityId] = action.payload.value
			},
			enableFunctionality: (state, action: PayloadAction<string>) => {
				const functionalityTemplateId = action.payload
				state.preset.items[functionalityTemplateId] = ''
			},
			disableFunctionality: (state, action: PayloadAction<string>) => {
				const functionalityTemplateId = action.payload
				delete state.preset.items[functionalityTemplateId]
			},
			setPresetItemValue: (
				state,
				action: PayloadAction<{
					functionalityId: string,
					value: string | FunctionalityValidator[]
				}>) => {
				const { functionalityId, value } = action.payload
				state.preset.items[functionalityId] = value
			}
		}
	}
)

export const { reducer: addNewPresetReducer } = newPresetSlice
export const { actions: addNewPresetActions } = newPresetSlice
