import { IInstance } from '../../../Instances'
import { FunctionalityTemplate } from '../../../Functionality'
import { FunctionalityValidator } from '../../../FunctionalityValidator'
import { FunctionalityPreset } from '../../../FunctionalityPreset'

export enum LicenseState {
	ACTIVE = 'active',
	DEBTOR = 'debtor',
	CONTRACT_TERMINATION = 'contractTermination',
	MQ_REGULATION_ISSUES = 'mqRegulationIssues',
	BUSINESS_FINANCIAL_ISSUES = 'businessFinancialIssues',
	BUGS_AND_INCIDENTS = 'bugsAndIncidents',
	MISSED_FEATURE = 'missedFeature',
	CANCELLED_FOR_TECH_REASONS = 'cancelledForTechnicalReasons',
	OTHERS = 'others',
	EXPIRED = 'expired',
}

export enum LicenseType {
	REAL = 'real',
	TRIAL = 'trial',
	_ = '',
}

export interface FunctionalityWithDisplayName {
	functionalityTemplateId: string
	displayName: string,
	value: string | Array<FunctionalityValidator>
}

export interface OneFunctionalityInSet {
	functionalityId: string,
	value: string | Array<FunctionalityValidator>
}

export type FunctionalitiesSet = Record<
	OneFunctionalityInSet['functionalityId'],
	OneFunctionalityInSet['value']
>

export interface ILicense {
	licenseId: string
	creationDate: string
	expirationDate?: Date
	type: LicenseType
	state: LicenseState
	maxLiveInstances: number
	isMaxLiveInstancesExceeded: boolean
	allowedVersions: string[]
	numberOfInstances: string
	clientBrief: {
		clientId: string
		companyNames: string[]
	}
	productBrief: {
		name: string
		productId: string
		functionalitiesTemplates: Array<FunctionalityTemplate>
		functionalitiesPresets: Array<FunctionalityPreset>
	}
	functionalitiesPresetId?: string
	functionalitiesSet?: FunctionalitiesSet
	functionalitiesWithDisplayName?: Array<FunctionalityWithDisplayName>
	instances?: Array<IInstance>
}

export interface LicenseUpdate {
	licenseId: string
	userId: string
	updateTime: string
	expirationDate: string
	type: LicenseType
	state: string
	maxLiveInstances: number
	allowedVersions: string[]
}

export interface ILicenseWithUpdates extends ILicense {
	licenseUpdates: Array<LicenseUpdate>
}

export interface LicenseSchema {
	cachedLicense: ILicenseWithUpdates
	licenseEditable: ILicenseWithUpdates
	isLoading: boolean
	error?: string
}
