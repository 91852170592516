import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../../shared/config/storeConfig/StoreSchema'
import { selectProductId } from '../../../../entities/Product'
import { showToast } from '../../../../shared/lib/showToast/showToast'
import { fetchProduct } from '../../../../entities/Product/model/services/fetchProduct'
import { fetchCommonProducts } from '../../../../entities/Common'
import { appHandleError } from '../../../../shared/lib/appHandleError/appHandleError'

export const deletePreset = createAsyncThunk<
	void,
	string,
	ThunkConfig<string>
>(
	'deletePreset',
	async (functionalityPresetId, thunkAPI) => {

		const { dispatch, extra: { api }, rejectWithValue, getState } = thunkAPI
		const productId = selectProductId(getState())

		try {
			await api.delete(`/products/${productId}/functionality-presets/${functionalityPresetId}`)

			showToast({
				type: 'success',
				toastId: 'deleteProductFunctionalitiesPreset',
				message: 'Success'
			})

			dispatch(fetchProduct(productId))
			dispatch(fetchCommonProducts)
		} catch (e) {
			console.log(e)
			appHandleError(e, 'deleteProductFunctionalitiesPreset', 'Failed to delete functionalities preset')
			return rejectWithValue('Error occurred on functionalities preset delete')
		}
	})