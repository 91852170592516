import React, { memo } from 'react'
import { useAppDispatch } from '../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { OneFunctionalityInSet } from '../../../entities/License'
import { FunctionalityPick, FunctionalityTemplate } from '../../../entities/Functionality'
import {
	FunctionalityPreset,
	presetsActions
} from '../../../entities/FunctionalityPreset'

interface PresetFunctionalityItemProps {
	functionalityTemplate: FunctionalityTemplate
	preset: FunctionalityPreset
	presetIndex: number
}

export const PresetFunctionalityItem = memo((props: PresetFunctionalityItemProps) => {
	const { functionalityTemplate, preset, presetIndex } = props

	const dispatch = useAppDispatch()
	const funcItemValue = preset?.items?.[functionalityTemplate.functionalityTemplateId]!
	const isFunctionalityPicked = Object.hasOwn(preset.items ?? {}, functionalityTemplate.functionalityTemplateId)

	const handlePresetFunctionalityValueChange = (funcSet: OneFunctionalityInSet) => {
		dispatch(presetsActions.setPresetItemValue({
			presetIndex,
			functionalityId: funcSet.functionalityId,
			value: funcSet.value
		}))
	}

	const handleDisablePresetFunctionality = (funcId: string) => {
		dispatch(presetsActions.disableFunctionality({
			presetIndex,
			functionalityTemplateId: funcId
		}))
	}

	const handleEnablePresetFunctionality = (funcId: string) => {
		dispatch(presetsActions.enableFunctionality({
			presetIndex,
			functionalityTemplateId: funcId
		}))
	}

	return (
		<FunctionalityPick
			key={functionalityTemplate.functionalityTemplateId}
			functionalityTemplate={functionalityTemplate}
			value={funcItemValue!}
			onFunctionalityValueChange={handlePresetFunctionalityValueChange}
			enableFunctionalityPermission={handleEnablePresetFunctionality}
			disableFunctionalityPermission={handleDisablePresetFunctionality}
			isFunctionalityPicked={isFunctionalityPicked}
		/>
	)
})