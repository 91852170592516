import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
	FunctionalitiesSchema,
	FunctionalitiesTemplatesModified,
	FunctionalityValueType
} from '../types/FunctionalityTemplate'
import { v4 as uuidv4 } from 'uuid'

const initialState: FunctionalitiesSchema = {
	error: '',
	isLoading: false,
	cachedFunctionalities: [],
	functionalitiesEditable: []
}

const functionalitiesSlice = createSlice({
	name: 'functionality',
	initialState,
	reducers: {
		setFunctionalities: (
			state, action: PayloadAction<Array<FunctionalitiesTemplatesModified>>
		) => {
			state.cachedFunctionalities = action.payload
			state.functionalitiesEditable = action.payload
		},
		resetFunctionalityState: (
			state,
			action: PayloadAction<number>
		) => {
			const funcIndex = action.payload
			state.functionalitiesEditable[funcIndex] = state.cachedFunctionalities[funcIndex]
		},
		addEmptyValue: (
			state,
			action: PayloadAction<number>
		) => {
			const funcIndex = action.payload
			state.functionalitiesEditable[funcIndex]?.values?.push({
				valueId: uuidv4(),
				value: ''
			})
		},
		removeValue: (
			state,
			action: PayloadAction<{ funcIndex: number, valueId: string }>
		) => {
			const { funcIndex, valueId } = action.payload

			state.functionalitiesEditable[funcIndex].values = state
				.functionalitiesEditable[funcIndex].values?.filter(
				(value) => value.valueId !== valueId
			) ?? state.functionalitiesEditable[funcIndex].values
		},
		editFunctionalityName: (
			state,
			action: PayloadAction<{ funcName: string, funcIndex: number }>
		) => {
			const { funcIndex, funcName } = action.payload
			state.functionalitiesEditable[funcIndex].functionalityName = funcName
		},
		editFunctionalityDisplayName: (
			state,
			action: PayloadAction<{ displayName: string, funcIndex: number }>
		) => {
			const { funcIndex, displayName } = action.payload
			state.functionalitiesEditable[funcIndex].displayName = displayName
		},
		editValue: (
			state,
			action: PayloadAction<{ value: string, funcIndex: number, valueId: string }>
		) => {
			const { value, funcIndex, valueId } = action.payload
			state.functionalitiesEditable[funcIndex].values = state.functionalitiesEditable[funcIndex]
				.values?.map(valueData => {
					if (valueData.valueId === valueId) {
						return { ...valueData, value: value }
					}
					return valueData
				}) ?? state.functionalitiesEditable[funcIndex].values
		},
		enableIsProductSetValue: (state, action: PayloadAction<{
			funcIndex: number,
		}>) => {
			const { funcIndex } = action.payload
			state.functionalitiesEditable[funcIndex].isProductSetValue = true
			state.functionalitiesEditable[funcIndex].type = FunctionalityValueType.INTEGER_VALUE
			state.functionalitiesEditable[funcIndex].values = null
		},
		disableIsProductSetValue: (state, action: PayloadAction<{
			funcIndex: number,
		}>) => {
			const { funcIndex } = action.payload
			state.functionalitiesEditable[funcIndex].isProductSetValue = false
			state.functionalitiesEditable[funcIndex].type = FunctionalityValueType.ENUM_VALUE
			state.functionalitiesEditable[funcIndex].values = [{
				valueId: uuidv4(),
				value: ''
			}]
		}
	}
})

export const { actions: functionalitiesAction } = functionalitiesSlice
export const { reducer: functionalitiesReducer } = functionalitiesSlice
